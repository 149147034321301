import './GalleryLanding.styles.scss';
import images from './Images';
import Thumbnail from './Thumbnail';
import React, { useState } from 'react';
import Modal from '../../components/Modal';
import Carousel from '../../components/Carousel';

const GalleryLanding = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [imgIndex, setImgIndex] = useState(0);

  const onImgClick = (e: React.MouseEvent<HTMLElement>) => {
    const target = e.target as HTMLElement;

    if (target.dataset.index) {
      setImgIndex(Number(target.dataset.index));
      setIsOpen(true);
    }
  };

  return (
    <>
      <section id="cr-page-gallery" onClick={(e) => onImgClick(e)}>
        {images.map((image, i) => (
          <Thumbnail img={image} key={i} index={i} />
        ))}
      </section>

      {isOpen && (
        <Modal onClose={() => setIsOpen(false)}>
          <Carousel index={imgIndex} images={images} />
        </Modal>
      )}
    </>
  );
};

export default GalleryLanding;
