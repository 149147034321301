import img_01 from '../../assets/images/guru-abhilasha-chudhary-saxena.jpeg';

const AboutAbhilasha = () => (
  <section className="cr-about-section">
    <div className="cr-heading">
      Meet the Guru - <h1>Abhilasha Chaudhary Saxena</h1>
    </div>

    <img src={img_01} alt="Guru Abhilasha Chaudhary Saxena" style={{ float: 'left', margin: '0 1.25rem 1.25rem 0' }} />

    <article>
      <p>
        It was the summer of 1991. Little Abhilasha wanted to take up a new pursuit during her summer holidays. Her
        elder sister had just joined a harmonium class. So, she decided to go along with hopes of learning something
        new. Guru Smt. Shashi Gupta identified little Abhilasha's talent. She saw the spark in her and encouraged her to
        take up Kathak. Her suggestion was life-changing. And once this journey of a lifetime began, there was no
        looking back.
      </p>
      <p>
        A journey that started at the tender age of 7 has today transformed into an illustrious one. Guru Abhilasha
        Chaudhary Saxena, a prodigy who was born in Mumbai, India, has been devoted to the Indian classical dance form
        Kathak since her childhood. She received her initial training from Guru Smt. Shashi Gupta, Guru Bireshwar Gautam
        and later from Guru Padma Sharma. Due to her flair for dancing and her dedication, she won many competitions
        during her early years. Her love for Kathak grew as she grew up and she decided to get a postgraduate degree in
        Alankar from a highly reputed institute in India. Post her formal education, she founded a registered dance
        institute in Mumbai named Nritya-Jhankar Cultural Academy. In a span of 9 years, she trained hundreds of
        students and many of them graduated with a Visharad Degree. Today, these trained kathak dancers are enjoying a
        rewarding career in the field of dance.
      </p>
      <p>
        To date, she has performed at numerous popular and mega-events and has had the privilege of sharing the stage
        with renowned personalities like Pandit Kalinath Mishra, Kathak queen Jayanti Mala, Nandita Puri and many more.
        She has also earned many prestigious awards, but the golden feather in her cap has been the title of Shringar
        Mani. It was conferred on her at the 'Kal-Ke-Kalakaar Sammelan' in the year 2000.
      </p>
      <p>
        A few years later, her life and career took wing. Even though she moved to the other side of the world, she has
        been deeply connected to her roots. But the purpose of her life has transformed into a bigger cause. Being miles
        away from her home country, now, it's her vision to promote Indian classical art forms and to make Kathak reach
        every corner of the USA. This vision of hers inspired her and she thought that there has to be kathak classes
        near me. So, she decided to lay the foundation of Classical Rhythms Academy in Marlton, New Jersey. She has also
        actively participated in competitions and kathak festivals here in the USA and has been associated with many
        educational and religious institutions to promote the classical Indian dance form, Kathak.
      </p>
    </article>
  </section>
);

export default AboutAbhilasha;
