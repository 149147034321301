import Header from '../components/header/Header';
import Body from './layout/Body';

const Layout = () => (
  <>
    <Header />
    <Body />
  </>
);

export default Layout;
