import '../../styles/about-section.scss';
import AboutAbhilasha from './AboutAbhilasha';
import AboutTushar from './AboutTushar';
import AboutPadma from './AboutPadma';
import AboutKalinath from './AboutKalinath';

const AboutLanding = () => (
  <>
    <AboutAbhilasha />
    <AboutTushar />
    <AboutPadma />
    <AboutKalinath />
  </>
);

export default AboutLanding;
