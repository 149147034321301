import AppNavLink from './NavigationLink';
import { ROUTES } from '../../Routing';
import { useEffect, useRef, useState } from 'react';
import Hamburger from './Hamburger';
import './Navigation.styles.scss';
import { CSSTransition } from 'react-transition-group';

const Navigation = () => {
  const [isMobile, setIsMobile] = useState<boolean>();
  const [showMobileNav, setShowMobileNav] = useState(false);
  const nodeRef = useRef(null);

  useEffect(() => {
    setIsMobile(window.innerWidth < 768);
  }, []);

  const nav = (
    <nav onClick={() => setShowMobileNav(false)}>
      <AppNavLink to={ROUTES.home.path} text={ROUTES.home.title} />
      <AppNavLink to={ROUTES.about.path} text={ROUTES.about.title} />
      <AppNavLink to={ROUTES.gallery.path} text={ROUTES.gallery.title} />
      <AppNavLink to={ROUTES.contact.path} text={ROUTES.contact.title} />
    </nav>
  );

  return isMobile ? (
    <>
      <Hamburger onClicked={(open) => setShowMobileNav(open)} setClose={showMobileNav} />
      <CSSTransition in={showMobileNav} timeout={500} classNames="cr-nav" unmountOnExit nodeRef={nodeRef}>
        <div id="cr-mobile-wrapper" ref={nodeRef}>
          {nav}
        </div>
      </CSSTransition>
    </>
  ) : (
    nav
  );
};

export default Navigation;
