import img0 from '../../assets/images/academy-students-1.jpeg';
import img1 from '../../assets/images/academy-students-2.jpeg';
import img2 from '../../assets/images/academy-students-3.jpeg';
import img3 from '../../assets/images/academy-students-4.jpeg';
import img4 from '../../assets/images/academy-students-5.jpeg';
import img5 from '../../assets/images/academy-students-6.jpeg';
import img6 from '../../assets/images/academy-students-7.jpeg';
import img7 from '../../assets/images/academy-students-8.jpeg';
import img8 from '../../assets/images/academy-students-9.jpeg';
import img9 from '../../assets/images/academy-students-10.jpeg';
import img10 from '../../assets/images/academy-students-11.jpeg';
import img11 from '../../assets/images/academy-students-12.jpeg';
import img12 from '../../assets/images/academy-students-13.jpeg';
import img13 from '../../assets/images/academy-students-14.jpeg';
import img14 from '../../assets/images/academy-students-15.jpeg';
import img15 from '../../assets/images/academy-students-16.jpeg';
import img16 from '../../assets/images/academy-students-17.jpeg';
import img17 from '../../assets/images/academy-students-18.jpeg';
import img18 from '../../assets/images/academy-students-19.jpeg';
import img19 from '../../assets/images/academy-events-1.jpeg';
import img20 from '../../assets/images/academy-events-2.jpeg';
import img21 from '../../assets/images/academy-events-3.jpeg';
import img22 from '../../assets/images/academy-events-4.jpeg';
import img23 from '../../assets/images/academy-events-5.jpeg';
import img24 from '../../assets/images/academy-events-6.jpeg';
import img25 from '../../assets/images/academy-events-7.jpeg';
import img26 from '../../assets/images/academy-events-8.jpeg';
import img27 from '../../assets/images/academy-events-9.jpeg';
import img28 from '../../assets/images/academy-events-10.jpeg';
import img29 from '../../assets/images/academy-events-11.jpeg';
import img30 from '../../assets/images/academy-events-12.jpeg';
import img31 from '../../assets/images/academy-events-13.jpeg';
import img32 from '../../assets/images/academy-events-14.jpeg';
import img33 from '../../assets/images/academy-events-15.jpeg';
import img34 from '../../assets/images/academy-events-16.jpeg';
import img35 from '../../assets/images/academy-events-17.jpeg';
import img36 from '../../assets/images/academy-events-18.jpeg';
import img37 from '../../assets/images/academy-events-19.jpeg';
import img38 from '../../assets/images/academy-events-20.jpeg';
import img39 from '../../assets/images/academy-events-21.jpeg';
import img40 from '../../assets/images/academy-events-23.jpeg';
import img41 from '../../assets/images/academy-events-24.jpeg';

const images = [
  img0,
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img19,
  img20,
  img21,
  img22,
  img23,
  img24,
  img25,
  img26,
  img27,
  img28,
  img29,
  img30,
  img31,
  img32,
  img33,
  img34,
  img35,
  img36,
  img37,
  img38,
  img39,
  img40,
  img41,
];

export default images;
