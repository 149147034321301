import img_01 from '../../assets/images/tushar_guha.jpeg';

const AboutTushar = () => (
  <section className="cr-about-section">
    <div className="cr-heading">
      <h1>Guru Dr. Tushar Guha</h1>
    </div>

    <img src={img_01} alt="Dr. Tushar Guha" style={{ float: 'right', margin: '0 0 1.25rem 1.25rem', width: '160px' }} />

    <article>
      <p>
        Guru Dr. Tushar Guha is an eminent corporate trainer, psychologist, educationist, performing artist and pioneer
        of personality development in India. With over 32 years of in-depth research and professional experience, he
        made history in the year 2000, when he became the first Personality Development Doctor in the world from the
        USA.
      </p>
      <p>
        He is the Founder and Chairman of <strong>Nrityanjali</strong> and an exponent of Indian classical dance, Indian
        folk dance and western dance. He is an actor, singer, dubbing artist, music composer, poet and director of
        various plays too. Dr. Guha has received critical acclaim and has performed in more than 3500 shows across the
        globe.
      </p>
      <p>
        He is a leader of actions rather than just words. He was given a Certificate of Appreciation for Courage by the
        Mumbai Police Commissioner for defusing a crowd of 400 rioters single-handedly on 5th Jan 1990. On the same day
        in 2017, Charushila Productions and New Bombay Design Company – Alumni FTII premiered the 90-minute documentary
        on the life and work of Guru Dr. Tushar Guha titled <strong>Nirjhar</strong>. Dr. Guha's research on Performing
        Arts and its practical application with Psychology in Social and Professional Life is available on TEDx.
      </p>
    </article>
  </section>
);

export default AboutTushar;
