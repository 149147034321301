import { FC, useEffect } from 'react';
import './Modal.styles.scss';

type Props = {
  onClose: () => void;
};

const Modal: FC<Props> = ({ onClose, children }) => {
  useEffect(() => {
    const listener = (ev: KeyboardEvent) => {
      if (ev.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keyup', listener);

    return () => document.removeEventListener('keyup', listener);
  }, [onClose]);

  return (
    <div id="cr-modal">
      <div role="button" onClick={onClose} className="cr-modal-close" title="Close" />
      <div className="cr-modal-content">{children}</div>
    </div>
  );
};

export default Modal;
