import Navigation from './Navigation';
import './Header.styles.scss';

const Header = () => {
  return (
    <header id="cr-header-tag">
      <section className="cr-content-max-width cr-header-wrapper">
        <h1>Classical Rhythms</h1>
        <Navigation />
      </section>
    </header>
  );
};

export default Header;
