import './ContactLanding.styles.scss';
import { useEffect, useState } from 'react';

const ContactLanding = () => {
  const studioTimes = {
    Sunday: ['Closed'],
    Monday: ['10 AM - 12 PM', '5 PM - 9 PM'],
    Tuesday: ['10 AM - 12 PM', '5 PM - 9 PM'],
    Wednesday: ['10 AM - 12 PM', '5 PM - 9 PM'],
    Thursday: ['10 AM - 12 PM'],
    Friday: ['10 AM - 12 PM', '5 PM - 9 PM'],
    Saturday: ['8 AM - 1 PM'],
  };

  const centerTimes = {
    Thursday: ['6 PM - 9 PM'],
  };

  const Table = (times: { [key: string]: string[] }) => (
    <>
      <p>
        <strong>Timings:</strong>
      </p>
      <table className="cr-location-time-table">
        <tbody>
          {Object.entries(times).map((row) => (
            <tr key={row[0]}>
              <td>{row[0]}</td>
              <td>{row[1].join('; ')}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );

  const [frame1Loading, setFrame1] = useState(true);
  const [frame2Loading, setFrame2] = useState(true);

  return (
    <section className="cr-about-section">
      <div className="cr-heading">Our Locations</div>

      <div className="cr-location-row">
        <section>
          <h3>Classical Rhythms Dance Academy Studio</h3>
          <p>
            3815 Church Rd, <br /> Mt Laurel Township, <br /> NJ 08054
          </p>
          {Table(studioTimes)}
        </section>

        {frame1Loading && <span>Loading map...</span>}
        <iframe
          title="Classical Rhythms Studio"
          src="https://maps.google.com/maps?q=3815%20church%20rd,%20mount%20laurel&t=&z=11&ie=UTF8&iwloc=&output=embed"
          onLoad={() => setFrame1(false)}
          style={{ display: `${frame1Loading ? 'none' : 'block'}` }}
        />
      </div>

      <div className="cr-location-row">
        <section>
          <h3>Indian Cultural Center</h3>
          <p>
            820 NJ-73, <br /> Evesham, <br /> NJ 08053
          </p>
          {Table(centerTimes)}
        </section>

        {frame2Loading && <span>Loading map...</span>}
        <iframe
          title="Indian Cultural Center"
          src="https://maps.google.com/maps?q=820%20NJ-73,%20Evesham&t=&z=11&ie=UTF8&iwloc=&output=embed"
          onLoad={() => setFrame2(false)}
          style={{ display: `${frame2Loading ? 'none' : 'block'}` }}
        />
      </div>
    </section>
  );
};

export default ContactLanding;
