import { Outlet } from 'react-router-dom';
import './Body.styles.scss';

const Body = () => (
  <main id="cr-body">
    <div className="cr-bg-image" />
    <div id="cr-layout-outlet" className="cr-content-max-width">
      <Outlet />
    </div>
  </main>
);

export default Body;
