import img0 from '../../assets/images/academy-events-25.jpeg';
import img1 from '../../assets/images/academy-events-2.jpeg';
import '../../styles/about-section.scss';
import './AboutKathak.styles.scss';

const AboutKathak = () => (
  <section className="cr-about-section">
    <div className="cr-heading">
      <h1>Kathak: Indian Classical Dance Form</h1>
    </div>

    <img src={img0} alt="Guru Abhilasha performing Katha" style={{ float: 'right', margin: '0 0 1.25rem 1.25rem' }} />

    <article>
      <p>
        In an era when stories were just narrated, India gave the world the art of visual storytelling. Kathak, one of
        India's oldest classical dance forms with a rich history, literally means a <strong>Katha</strong> or a story.
        Kathak’s origin is traditionally attributed to the travelling bards in ancient Northern India who were known as{' '}
        <strong>kathakars</strong> or storytellers.
      </p>
      <p>
        With graceful Kathak <strong>mudras</strong> (hand gestures) and poses, impressive footwork with Kathak{' '}
        <strong>ghungroos</strong> and captivating facial expressions, Kathak is found in 3 major forms or{' '}
        <strong>gharanas</strong>. Each Kathak gharana lays more emphasis on a particular body movement. These gharanas,
        namely Lucknow Gharana, Jaipur Gharana and Banaras Gharana, derive their names from their respective city of
        origin. Right from the Bhakti (devotion) movement to Royal Palaces and the present day, Kathak has thrived and
        evolved over the centuries. It is a highly revered dance form not just in India but even overseas.
      </p>
    </article>

    <img className="cr-about-kathak-image" src={img1} alt="Guru Abhilasha performing Katha" />
  </section>
);

export default AboutKathak;
