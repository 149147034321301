import './Slideshow.styles.scss';
import { FC, useEffect, useRef, useState } from 'react';

type Props = {
  images: string[];
};

const Slideshow: FC<Props> = ({ images }) => {
  const imgLen = images.length;
  const last = imgLen - 1;

  const initialOffsets = Array(imgLen).fill(-1);
  const [imgOffsets, setImgOffsets] = useState(initialOffsets);

  const initialHideClasses = Array(imgLen).fill('');
  const [hideClasses, setHideClasses] = useState(initialHideClasses);

  const [currIndex, setCurrIndex] = useState(0);
  const [paused, setPaused] = useState(false);

  useEffect(() => {
    const interval = window.setInterval(() => {
      if (!paused) {
        const imgToMoveIndex = currIndex === 0 ? last : currIndex - 1;

        const newOffsets = [...imgOffsets.map((v) => v - 1)];
        newOffsets[imgToMoveIndex] += imgLen;

        const newHideClasses = [...initialHideClasses];
        newHideClasses[imgToMoveIndex] = 'hide';

        setCurrIndex(currIndex === last ? 0 : currIndex + 1);
        setHideClasses(newHideClasses);
        setImgOffsets(newOffsets);
      }
    }, 7000);

    return () => clearInterval(interval);
  }, [currIndex, imgLen, imgOffsets, initialHideClasses, last, paused]);

  const ref = useRef<HTMLElement>(null);
  useEffect(() => {
    const currentRef = ref.current;

    currentRef?.addEventListener('mouseenter', () => setPaused(true));
    currentRef?.addEventListener('mouseleave', () => setPaused(false));

    return () => {
      currentRef?.removeEventListener('mouseenter', () => {});
      currentRef?.removeEventListener('mouseleave', () => {});
    };
  });

  return (
    <section className="cr-slideshow" ref={ref}>
      <div className="cr-slideshow-images">
        <div
          className={`cr-slideshow-image-wrapper ${hideClasses[last]}`}
          style={{ transform: `translateX(${imgOffsets[last]}00%)` }}
        >
          <img src={images[last]} className="cr-slideshow-image" alt="Classical Rhythms" />
        </div>

        {images.slice(0, last).map((image, i) => (
          <div
            className={`cr-slideshow-image-wrapper ${hideClasses[i]}`}
            key={i}
            style={{ transform: `translateX(${imgOffsets[i]}00%)` }}
          >
            <img src={image} className="cr-slideshow-image" alt="Classical Rhythms" />
          </div>
        ))}
      </div>
    </section>
  );
};

export default Slideshow;
