import { Link } from 'react-router-dom';
import { ROUTES } from '../../Routing';

const AboutAcademy = () => (
  <section className="cr-about-section">
    <div className="cr-heading">
      <h1>Classical Rhythms Dance Academy</h1>
    </div>

    <article>
      <p>
        Classical Rhythms Dance Academy is the brainchild of Guru Abhilasha Chaudhary Saxena. With decades of training
        and experience in this beautiful dance form, she ran a very successful dance academy in the city of Mumbai for
        15 years under the banner <strong>Nritya Jhankar Cultural Academy (NJCA)</strong>. Her students have
        participated in and won many competitions across India and she herself led the students in many competitions and
        performances at some of the most prestigious dance & culture festivals across India. NJCA is now thriving under
        the able leadership of Guru Abhilasha's younger sister, Guru Nitasha Chawdhary.
      </p>
      <p>
        In 2008, Guru Abhilasha moved to the USA and was faced with the challenge of reinventing herself and starting
        all over again. What started as a small Kathak batch of 7 students is now a full fledged academy of 50+
        students. The academy was registered in 2018 under the banner of Classical Rhythms Dance Academy. Today, the
        academy operates at two locations and it's Guru's goal to make Kathak and the academy reach every corner of the
        US.
      </p>
      <p>
        <Link to={ROUTES.about.path}>Read more about Guru Abhilasha</Link>
      </p>
    </article>
  </section>
);

export default AboutAcademy;
