import './Hamburger.styles.scss';
import { FC, useEffect, useState } from 'react';

type Props = {
  onClicked: (isOpen: boolean) => void;
  setClose: boolean;
};

const Hamburger: FC<Props> = ({ onClicked, setClose }) => {
  const [showClose, setShowClose] = useState(setClose);

  useEffect(() => setShowClose(setClose), [setClose]);

  const onClick = () => {
    setShowClose(!showClose);
    onClicked(!showClose);
  };

  return (
    <div id="cr-hamburger" className={showClose ? 'close' : ''} role="button" onClick={onClick}>
      <div className="bar1" />
      <div className="bar2" />
      <div className="bar3" />
    </div>
  );
};

export default Hamburger;
