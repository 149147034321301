import { FC } from 'react';
import './Thumbnail.styles.scss';

type Props = {
  img: string;
  index: number;
};

const Thumbnail: FC<Props> = ({ img, index }) => (
  <div className="cr-thumbnail">
    <img src={img} alt="Classical Rhythms Dance Academy Students" data-index={index} />
  </div>
);

export default Thumbnail;
