import React, { FC, useEffect, useRef, useState } from 'react';
import './Carousel.styles.scss';

enum Direction {
  next,
  previous,
}

type Props = {
  index: number;
  images: string[];
};

const Carousel: FC<Props> = ({ index, images }) => {
  const imgLen = images.length;

  const [imgIndex, setImgIndex] = useState(index);
  const [fadeClass, setFadeClass] = useState<string>();
  const imgRef = useRef<HTMLImageElement>(null);

  // fade-in image that was set after fade-out animation ended
  useEffect(() => {
    setFadeClass('fade-in');
  }, [imgIndex]);

  // Run once to remove listener
  useEffect(() => () => imgRef.current?.removeEventListener('transitionend', () => {}), []);

  const onTransitionEnd = (id: number) => setImgIndex(id);

  const fadeOutImg = (listener: () => void) => {
    imgRef.current?.addEventListener('transitionend', listener);
    setFadeClass('fade-out');
  };

  const onArrowClick = (direction: Direction) => {
    let id: number;
    if (direction === Direction.next) {
      id = imgIndex === imgLen - 1 ? 0 : imgIndex + 1;
    } else {
      id = imgIndex === 0 ? imgLen - 1 : imgIndex - 1;
    }

    fadeOutImg(() => onTransitionEnd(id));
  };

  useEffect(() => {
    const listener = (ev: KeyboardEvent) => {
      if (ev.key === 'ArrowLeft') {
        onArrowClick(Direction.previous);
      } else if (ev.key === 'ArrowRight') {
        onArrowClick(Direction.next);
      }
    };

    document.addEventListener('keyup', listener);

    return () => document.removeEventListener('keyup', listener);
  });

  const onIndicatorClick = (e: React.MouseEvent<HTMLElement>) => {
    const target = e.target as HTMLElement;

    if (target.dataset.index) {
      fadeOutImg(() => onTransitionEnd(Number(target.dataset.index)));
    }
  };

  return (
    <div id="cr-carousel">
      <div className="cr-carousel-button left" onClick={() => onArrowClick(Direction.previous)} title="Previous">
        <div />
      </div>

      <img src={images[imgIndex]} alt="Classical Rhythms" className={`cr-carousel-image ${fadeClass}`} ref={imgRef} />

      <div className="cr-carousel-button right" onClick={() => onArrowClick(Direction.next)} title="Next">
        <div />
      </div>

      <div className="cr-carousel-indicators" role="button" onClick={(e) => onIndicatorClick(e)}>
        {images.map((v, i) => (
          <span
            className={`cr-carousel-dot ${imgIndex === i ? 'active' : ''}`}
            key={i}
            data-index={i}
            title={`${i + 1} of ${imgLen}`}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;
