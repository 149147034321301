import Slideshow from '../../components/Slideshow';
import img0 from '../../assets/images/CRDA banner 1.png';
import img1 from '../../assets/images/CRDA banner 2.png';
import AboutKathak from './AboutKathak';
import AboutAcademy from './AboutAcademy';

const HomeLanding = () => {
  const images = [img0, img1, img0, img1];

  return (
    <>
      <Slideshow images={images} />
      <AboutAcademy />
      <AboutKathak />
    </>
  );
};

export default HomeLanding;
