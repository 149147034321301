import img_01 from '../../assets/images/kalinath_mishra.jpeg';

const AboutKalinath = () => (
  <section className="cr-about-section">
    <div className="cr-heading">
      <h1>Pandit Kalinath Mishra</h1>
    </div>

    <img
      src={img_01}
      alt="Pandit Kalinath Mishra"
      style={{ float: 'right', margin: '0 0 1.25rem 1.25rem', width: '200px' }}
    />

    <article>
      <p>
        Pandit Kalinath Mishra, one of India’s foremost and excellent Tabla Players, was born in the family of the
        renowned musician Pandit Madan Mishra. He learnt to play the Tabla from Pandit Kishan Maharaj of the Banaras
        Gharana.
      </p>
      <p>
        Over the years, he has developed a unique style of playing wherein he puts tremendous energy with his nimble
        fingers, thereby creating melodious beats. His playing is effortless, and despite being a solo percussionist,
        his ability to adapt to varied ways of playing makes him an exceptional accompanying artist.
      </p>
      <p>
        He has worked with Pandit Shivkumar Sharma, Pandit Hariprasad Chaurasia, Pandit Birju Maharaj, Late Smt. Sitara
        Devi, and many more. He also has many prestigious festivals and events to his credit. He has enthralled viewers
        with his performances on TV channels as well. His exceptional playing has taken him to stages across the world.
      </p>
      <p>
        He founded Sangeet Samagam Trust in memory of his father and his Guru, which holds a two-day event every year
        wherein every artist gets an opportunity to either perform or watch some unique and extraordinary performances.
        Throughout his career, he has made great contribution to Kathak music and songs. At present, he devotes his
        precious time to impart his knowledge to young and ambitious students.
      </p>
    </article>
  </section>
);

export default AboutKalinath;
