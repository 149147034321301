import { NavLink } from 'react-router-dom';
import { FC } from 'react';
import './NavigationLink.styles.scss';

type Props = {
  to: string;
  text: string;
};

const AppNavLink: FC<Props> = ({ to, text }) => (
  <NavLink className="cr-app-nav-link" to={to}>
    {text}
  </NavLink>
);

export default AppNavLink;
