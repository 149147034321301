const AboutPadma = () => (
  <section className="cr-about-section">
    <div className="cr-heading">
      <h1>Guru Dr. Padma Sharma</h1>
    </div>

    <article>
      <p>
        Guru Dr. Padma Sharma has been in the field of Kathak for over five decades. She earned her dance degree,{' '}
        <strong>Nritya Nipun</strong> in Kathak, in 1959 from Bhatkhande Sangit Vidyapith, Lucknow. She has trained
        under various legends such as Guru Mohan Rao Kallianpurkar, Guru Lacchu Maharaj, briefly under Guru Shambhu
        Maharaj and Pandit Birju Maharaj.
      </p>
      <p>
        She learnt vocal music under Guru S. N. Ratanjankar and learnt Bharatanatyam under the guidance of Guru L. P.
        Swamy, Madras. She has exposure to other forms of classical and folk dances too. Guru Padma Sharma has given
        innumerable performances at prestigious Sammelans and dance dramas/ballet such as Usha Swapna, Amrapali,
        Shakuntla, Radha Piya Pyari and Gangaur among others.
      </p>
      <p>
        She also has titles such as Sringar Mani, Sarangdev Fellowship and Shivali Nritya Shiromani to her name. Guru
        Padma Sharma has been training advanced practitioners in Kathak from India, UK, Sri Lanka, South Korea and
        Portugal. She has assisted her Guru Lacchu Maharaj in many Hindi films. She has also won acclaim for the
        choreography of Pan Khaye Saiyan from Teesri Kasam and even for other films like Pakeeza, Benazir and Ek Nazar.
      </p>
      <p>
        She carried out a study on folk dances of Uttar Pradesh, which formed the basis for her thesis. She has written
        innumerable articles and has been an examiner for the evaluation of doctoral work for PhD degrees. She is one of
        the leading Kathak Gurus in the country and is on the regional panel for a national scholarship being awarded by
        the Ministry of Human Resources. She is also an advisory member for the ISTD, London. She is associated with
        cultural organisations such as Sur Singar Samsad, Indira Nritya Kendra and is also a Director of Bhatkhande
        Sangit Vidyapith, Lucknow.
      </p>
    </article>
  </section>
);

export default AboutPadma;
