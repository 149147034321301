import { Route, Routes } from 'react-router-dom';
import Layout from './pages/Layout';
import React from 'react';
import AboutLanding from './pages/about/AboutLanding';
import GalleryLanding from './pages/gallery/GalleryLanding';
import HomeLanding from './pages/home/HomeLanding';
import ContactLanding from './pages/contact/ContactLanding';

export const ROUTES = {
  home: { path: '/', title: 'Home' },
  about: { path: '/about', title: 'About Us' },
  gallery: { path: '/gallery', title: 'Gallery' },
  social: { path: '/social', title: 'Social Media' },
  contact: { path: '/contact', title: 'Contact Us' },
};

const AppRoutes = () => (
  <Routes>
    <Route path={ROUTES.home.path} element={<Layout />}>
      <Route index element={<HomeLanding />} />
      <Route path={ROUTES.about.path} element={<AboutLanding />} />
      <Route path={ROUTES.gallery.path} element={<GalleryLanding />} />
      <Route path={ROUTES.contact.path} element={<ContactLanding />} />
    </Route>
  </Routes>
);

export default AppRoutes;
